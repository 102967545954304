var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"get-started-modal get-started-no-invited",attrs:{"adaptive":true,"name":"get-started-no-invited","height":"auto","width":"900","scrollable":""}},[_c('div',{staticClass:"close-modal",on:{"click":function($event){return _vm.$modal.hide('get-started-no-invited')}}},[_c('img',{attrs:{"src":require("@assets/img/icons/close.svg"),"alt":"X"}})]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-lg-6 copy"},[_c('h1',[_vm._v("Welcome to Memo’d!")]),_c('p',[_vm._v("Thanks for your interest in joining the Founding Creator Program. Our app is only made possible by talented creators like you. We’d love to know more about you.")])]),_c('div',{staticClass:"col-12 col-lg-6"},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitFounderRequest)}}},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"name"}},[_vm._v("First Name:")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstname),expression:"firstname"}],staticClass:"form-control",attrs:{"id":"name","type":"text","placeholder":"Enter your first name"},domProps:{"value":(_vm.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstname=$event.target.value}}})])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md"},[_c('ValidationProvider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"lastname"}},[_vm._v("Last Name:")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastname),expression:"lastname"}],staticClass:"form-control",attrs:{"id":"lastname","type":"text","placeholder":"Enter your last name"},domProps:{"value":(_vm.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastname=$event.target.value}}})])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md"},[_c('ValidationProvider',{attrs:{"mode":"lazy","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address:")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":"Enter your email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md"},[_c('ValidationProvider',{attrs:{"name":"expertise","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"expertise"}},[_vm._v("Area of Expertise:")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.area_expertise),expression:"area_expertise"}],staticClass:"form-control",attrs:{"id":"expertise","type":"text","placeholder":"Enter your expertise"},domProps:{"value":(_vm.area_expertise)},on:{"input":function($event){if($event.target.composing){ return; }_vm.area_expertise=$event.target.value}}})])]}}],null,true)})],1)]),_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length }},[_c('label',{attrs:{"for":"description"}},[_vm._v("Where can we see examples of your writing?")]),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"form-control",attrs:{"id":"description","placeholder":"(Publications, Blog, Twitter, Instagram, Goodreads, etc.)","rows":"3"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})])]}}],null,true)})],1),_c('div',{staticClass:"footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"disabled":!valid}},[_vm._v(" Request Early Access ")]),_c('p',{staticClass:"disclaimer"},[_vm._v(" by tapping on Get Early Access you are agreeing"),_c('br'),_c('a',{attrs:{"href":"/founders/program-terms-of-service","target":"_blank"}},[_vm._v("\"Terms and Conditions\"")]),_vm._v(" and "),_c('a',{attrs:{"href":"/legal/privacy-policy","target":"_blank"}},[_vm._v("\"Privacy Policies\"")])])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }