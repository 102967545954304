<template>
    <modal
        class="get-started-modal get-started-no-invited"
        :adaptive="true"
        name="get-started-no-invited"
        height="auto"
        width="900"
        scrollable
    >
        <div class="close-modal" @click="$modal.hide('get-started-no-invited')">
            <img src="@assets/img/icons/close.svg" alt="X">
        </div>
        <div class="row no-gutters">
            <div class="col-12 col-lg-6 copy">
                <h1>Welcome to Memo’d!</h1>
                <p>Thanks for your interest in joining the Founding Creator Program. Our app is only made possible by talented creators like you. We’d love to know more about you.</p>
            </div>
            <div class="col-12 col-lg-6">
                <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
                    <form novalidate @submit.prevent="handleSubmit(submitFounderRequest)">
                        <div class="body">
                            <div class="row">
                                <div class="col-12 col-md">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="name"
                                        rules="required"
                                    >
                                        <div :class="{ 'required' : errors.length }" class="form-group">
                                            <label for="name">First Name:</label>
                                            <span v-if="errors.length">({{ errors[0] }})</span>
                                            <input
                                                id="name"
                                                v-model="firstname"
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter your first name"
                                            >
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="lastname"
                                        rules="required"
                                    >
                                        <div :class="{ 'required' : errors.length }" class="form-group">
                                            <label for="lastname">Last Name:</label>
                                            <span v-if="errors.length">({{ errors[0] }})</span>
                                            <input
                                                id="lastname"
                                                v-model="lastname"
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter your last name"
                                            >
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        mode="lazy"
                                        name="email"
                                        rules="required|email"
                                    >
                                        <div :class="{ 'required' : errors.length }" class="form-group">
                                            <label for="email">Email Address:</label>
                                            <span v-if="errors.length">({{ errors[0] }})</span>
                                            <input
                                                id="email"
                                                v-model="email"
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter your email"
                                            >
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="expertise"
                                        rules="required"
                                    >
                                        <div :class="{ 'required' : errors.length }" class="form-group">
                                            <label for="expertise">Area of Expertise:</label>
                                            <span v-if="errors.length">({{ errors[0] }})</span>
                                            <input
                                                id="expertise"
                                                v-model="area_expertise"
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter your expertise"
                                            >
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="description"
                                rules="required"
                            >
                                <div :class="{ 'required' : errors.length }" class="form-group">
                                    <label for="description">Where can we see examples of your writing?</label>
                                    <span v-if="errors.length">({{ errors[0] }})</span>
                                    <textarea
                                        id="description"
                                        v-model="description"
                                        placeholder="(Publications, Blog, Twitter, Instagram, Goodreads, etc.)"
                                        class="form-control"
                                        rows="3"
                                    />
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="footer">
                            <button :disabled="!valid" class="btn btn-secondary">
                                Request Early Access
                            </button>
                            <p class="disclaimer">
                                by tapping on Get Early Access you are agreeing<br>
                                <a href="/founders/program-terms-of-service" target="_blank">"Terms and Conditions"</a> and <a href="/legal/privacy-policy" target="_blank">"Privacy Policies"</a>
                            </p>
                        </div>
                    </form>
                </validationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import { extend } from "vee-validate";

extend("passwordConfirm", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "Password confirmation does not match"
});

export default {
    name: "GetStartedNoInvited",
    data() {
        return {
            firstname: "",
            lastname: "",
            email: "",
            area_expertise: "",
            description: ""
        };
    },
    methods: {
        submitFounderRequest() {
            axiosPublic.post(`/invitations-web/request`, this.$data).then(() => {
                this.$modal.hide("get-started-no-invited");
                this.$modal.show("thanks-modal");
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        }
    }
}
</script>
